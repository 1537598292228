import csrfTokenStoreInstance from "../../csrf_token_store";
import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  let isExist = document.querySelector(".free-spaces-form");

  if (isExist == null) {
    return;
  }

  let rootElement = () => {
    return document.querySelector(".free-spaces-form");
  };

  let isPost = () => {
    return rootElement().classList.contains("post");
  };

  let isPut = () => {
    return rootElement().classList.contains("put");
  };

  let isAnonymousEnabled = () => {
    return rootElement().querySelector("#topic_anonymous_enabled").checked;
  };

  let anonymousEnabledElement = () => {
    return rootElement().querySelector("#topic_anonymous_enabled");
  };

  let initFormState = () => {
    let nameElement = rootElement().querySelector(".name-partial");
    let inputCommentElement = nameElement.querySelector(".input-comment-name");
    let inputElement = inputCommentElement.querySelector("#comment_name");
    let notLoginMessageElement = nameElement.querySelector(
      ".not-login-message"
    );
    let userIconImageElement = rootElement().querySelector(".user-icon img");

    if (loginStateStore.isLoggedIn()) {
      notLoginMessageElement.remove();
      userIconImageElement.src = loginStateStore.getUserIconPath();

      if (isPost()) {
        inputCommentElement.style.display = "none";
        anonymousEnabledElement().checked = false;
      }
      if (isPut()) {
        if (isAnonymousEnabled()) {
          inputCommentElement.style.display = "block";
          userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
        } else {
          inputElement.value = "名無し";
          inputCommentElement.style.display = "none";
        }
      }
    } else {
      anonymousEnabledElement().checked = true;
    }
  };

  anonymousEnabledElement().addEventListener("click", function (e) {
    let nameElement = rootElement().querySelector(".name-partial");
    let inputCommentElement = nameElement.querySelector(".input-comment-name");
    let notLoginMessageElement = nameElement.querySelector(
      ".not-login-message"
    );
    let postButtonElement = rootElement().querySelector("#post-button");
    let userIconImageElement = rootElement().querySelector(".user-icon img");

    if (loginStateStore.isLoggedIn()) {
      if (isAnonymousEnabled()) {
        inputCommentElement.style.display = "block";
        userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
      } else {
        inputCommentElement.style.display = "none";
        userIconImageElement.src = loginStateStore.getUserIconPath();
      }
    } else {
      if (isAnonymousEnabled()) {
        inputCommentElement.style.display = "block";
        notLoginMessageElement.style.display = "none";
        postButtonElement.disabled = false;
      } else {
        inputCommentElement.style.display = "none";
        notLoginMessageElement.style.display = "block";
        postButtonElement.disabled = true;
      }
    }
  });

  let imageSelectorDialog = document.querySelector("a#image-selector-button");

  imageSelectorDialog.addEventListener("click", function (event) {
    event.preventDefault();

    let fileSelector = document.querySelector("input.image-field");

    fileSelector.addEventListener("change", function (e) {
      let imagePartial = document.getElementsByClassName("image-partial")[0];
      let pasteImage = document.getElementsByClassName("paste-image")[0];

      let files = e.target.files;

      if (files.length == 0) {
        let imageElement = pasteImage.querySelector("img");

        if (imageElement != null) {
          imageElement.remove();
          return;
        }

        return;
      }

      for (var i = 0, file; (file = files[i]); i++) {
        if (!file.type.match("image.*")) {
          continue;
        }

        var reader = new FileReader();

        reader.onload = (function (f) {
          return function (e) {
            try {
              let imageElement = pasteImage.querySelector("img");

              if (imageElement != null) {
                imageElement.remove();
                return;
              }

              var uploagImageElement = document.createElement("img");
              uploagImageElement.className = "upload-image";
              pasteImage.appendChild(uploagImageElement);

              var img = pasteImage.querySelector("img.upload-image");
              img.src = e.target.result;
            } catch (e) { }
          };
        })(file);

        reader.readAsDataURL(file);

        return;
      }
    });

    fileSelector.click();
  });

  let postButtonElement = rootElement().querySelector("#post-button");

  postButtonElement.addEventListener("click", function (event) {
    event.preventDefault();

    var inputTextValue = document.querySelector('.title-input-text input').value;
    const form = event.target.closest('form');

    const stringsToCheck = ['思い出せ', '忘れた', 'わからない'];
    if (stringsToCheck.some((str) => inputTextValue.includes(str))) {
      if (!confirm('⚠思い出せないマンガに関する質問は、専用コーナー(https://manba.co.jp/want_to_recall)の利用を推奨しております。\n投稿を続けますか？')) {
        return false;
      }
    }

    let authenticityTokenElements = rootElement().querySelectorAll(
      "input[name='authenticity_token'][type='hidden']"
    );
    authenticityTokenElements.forEach(element => {
      element.value = csrfTokenStoreInstance.get().csrfToken;
    });

    window.removeEventListener('beforeunload', beforeUnLoadEvent);
    form.submit();
  });

  let setResizeForm = () => {
    let commentBodyForm = document.querySelector("#comment_body");
    let clientHeight = commentBodyForm.clientHeight;
    commentBodyForm.addEventListener("input", () => {
      commentBodyForm.style.height = clientHeight + "px";
      let scrollHeight = commentBodyForm.scrollHeight;
      commentBodyForm.style.height = scrollHeight + "px";
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initFormState();
  });

  setResizeForm();

  let beforeUnLoadEvent = (event) => {
    console.log("beforeUnLoadEvent start");
    console.log(ignoreBeforeUnload);

    if (ignoreBeforeUnload) return;

    let topicTitleForm = document.querySelector("#topic_title");
    let topicTitle = topicTitleForm.value;

    let commentBodyForm = document.querySelector("#comment_body");
    let commentBody = commentBodyForm.value;

    if (commentBody.length > 0 || topicTitle.length > 0) {
      event.preventDefault();
      event.returnValue = "このページを離れますか？";
      return "このページを離れますか？";
    }
    return;
  };

  window.addEventListener('beforeunload', beforeUnLoadEvent)

});
