// 参考ページ
// https://github.com/algolia/instantsearch.js/blob/61ad247c252a42743efa35d0e32d1802119e53a2/stories/panel.stories.ts#L58

import algoliasearchApiInstance from "../../algoliasearch/api";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let isExist = document.querySelector(".free-spaces-filter-search-module");

  if (isExist == null) {
    return;
  }

  const search = instantsearch({
    indexName: algoliasearchApiInstance.getTopicIndexName(),
    searchClient: algoliasearchApiInstance.getClient(),
    insights: true,
    routing: {
      router: instantsearch.routers.history(),
      stateMapping: {
        stateToRoute(uiState) {
          return {
            page: uiState.Topic_production.page,
          };
        },
        routeToState(routeState) {
          return {
            Topic_production: {
              page: routeState.page || 1,
            },
          };
        },
      },
    }
  });

  search.addWidget(
    instantsearch.widgets.analytics({
      pushFunction(formattedParameters, state, results) {
        gtag('config', 'G-ZVDPNNBLJT', {
          'page_location': location.href,
          'page_referrer': document.referrer,
        });

      },
      triggerOnUIInteraction: true,
      pushInitialSearch: true,
      pushPagination: true
    })
  );

  search.addWidget(
    instantsearch.widgets.configure({
      hitsPerPage: 60,
      clickAnalytics: true,
      filters: "is_general_audience:true",
    })
  );

  search.addWidget(
    instantsearch.widgets.searchBox({
      container: document.querySelector(".free-spaces-filter-search-input"),
      placeholder: "絞り込み",
      autofocus: true,
      showReset: false,
      showSubmit: false,
      showLoadingIndicator: false,
    })
  );


  const renderGridHits = (renderOptions, isFirstRender) => {
    const { hits, widgetParams } = renderOptions;

    widgetParams.container.innerHTML = `
      <div class="free-spaces-grid-module">
         <div class="free-spaces-grid">
          ${hits
        .map(
          item =>
            `<article class="free-spaces-topic-grid-module" data-topic-id="${item.objectID}">
                  <a href=${item.redirect_url}>
                    <div class="free-spaces-topic-grid">
                      <div class="main-contents">
                        ${item.free_space_thumbnail_url ? `
                          <div class="thumbnail">
                            <img loading="lazy" src="${item.free_space_thumbnail_url}" alt="${item.title}">
                          </div>
                        ` : ""}
                        <div class="title-contents">
                        <h3 class="title">${item.title}</h3>
                        <div class="metadata-contents">
                          <div class="comments-count">
                            <div class="type">
                              <div class="icon">
                                <div class="icon-module">
                                  <span aria-hidden="true" class="material-icons-outlined dark-gray md-18">mode_comment</span></div>
                                </div>
                                <div class="text">
                                  ${item.comments_count}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </article>
            `
        )
        .join('')}
  </div></div>`;
  };

  // Create the custom widget
  const customHits = instantsearch.connectors.connectHits(renderGridHits);

  // Instantiate the custom widget
  search.addWidgets([
    customHits({
      container: document.querySelector('.free-spaces-filter-search-hits'),
    })
  ]);

  const renderPagination = (renderOptions, isFirstRender) => {
    const {
      pages,
      currentRefinement,
      nbPages,
      nbHits,
      isFirstPage,
      isLastPage,
      refine,
      createURL,
      widgetParams
    } = renderOptions;

    if (isFirstRender) {
      let div = document.createElement("div");
      div.setAttribute("class", "filter-search-pagination-module");

      let div_0 = document.createElement("div");
      div_0.setAttribute("class", "popular-pagination");
      div.appendChild(div_0);

      let div_1 = document.createElement("div");
      div_1.setAttribute("class", "page-items");
      div_1.setAttribute("id", "page-items");
      div_0.appendChild(div_1);

      widgetParams.container.appendChild(div);
    }

    const container = widgetParams.container.querySelector("#page-items");

    if (widgetParams.container.classList.contains("top") === true) {
      if (nbHits === 0) {
        widgetParams.container.classList.add("hide");
        widgetParams.container.classList.remove("show");
        widgetParams.container.style.display = "none";
      }

      if (isFirstPage === true) {
        widgetParams.container.classList.add("hide");
        widgetParams.container.classList.remove("show");
        widgetParams.container.style.display = "none";
      } else {
        widgetParams.container.classList.remove("hide");
        widgetParams.container.classList.add("show");
        widgetParams.container.style.display = "block";
      }
    }

    if (widgetParams.container.classList.contains("bottom") === true) {
      if (nbHits === 0) {
        widgetParams.container.classList.add("hide");
        widgetParams.container.classList.remove("show");
        widgetParams.container.style.display = "none";
      } else {
        widgetParams.container.classList.remove("hide");
        widgetParams.container.classList.add("show");
        widgetParams.container.style.display = "block";
      }
    }

    container.innerHTML = `
        ${!isFirstPage
        ? `
              <a class="page-item" href="${createURL(0)}" data-value="${0}">
                <div class="link next"><<</div>
              </a>
              `
        : `
              <div class="page-item disabled"><<</div>
              `
      }
        ${pages
        .map(
          page => `
              <a class="${currentRefinement === page ? "page-item current" : "page-item"
            } href="${createURL(page)}" data-value="${page}" >
                <div class="link">${page + 1}</div>
              </a>
            `
        )
        .join("")
      }
        ${!isLastPage
        ? `
              <a class="page-item" href="${createURL(
          nbPages - 1
        )}" data-value="${nbPages - 1}">
                <div class="link next">>></div>
              </a>
              `
        : `
              <div class="page-item disabled">>></div>
              `
      }
    `;
    [...container.querySelectorAll("a")].forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();
        refine(event.currentTarget.dataset.value);

        window.scrollTo({
          top: 0
        });
      });
    });
  };

  // Create the custom widget
  const customPagination = instantsearch.connectors.connectPagination(
    renderPagination
  );

  // Instantiate the custom widget
  search.addWidget(
    customPagination({
      container: document.querySelector(".free-spaces-filter-search-pagination.bottom"),
      padding: 2
    })
  );

  // Instantiate the custom widget
  search.addWidget(
    customPagination({
      container: document.querySelector(".free-spaces-filter-search-pagination.top"),
      padding: 2
    })
  );

  search.start();

  const searchButton = document.querySelector(".free-spaces-filter-search-button");
  searchButton.addEventListener("click", function (event) {
    let searchInput = document.querySelector(".free-spaces-filter-search-input input");
    let value = searchInput.value;
    let redirectUrl = `/search/free_spaces?q=${value}`;
    location.href = redirectUrl;
  });

  const searchInput = document.querySelector(".free-spaces-filter-search-input input");
  searchInput.addEventListener("keypress", function (event) {
    let value = searchInput.value;
    if (value !== "" && event.key === "Enter") {
      let value = searchInput.value;
      let redirectUrl = `/search/free_spaces?q=${value}`;
      location.href = redirectUrl;
    }
  });

});
