import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let module = document.querySelector(".authors-floating-menu-module");

  if (module == null) {
    return;
  }

  let floatingMenuButtonElement = module.querySelector(".floating-menu-button");
  let floatingMenuDialogElement = module.querySelector(".floating-menu-dialog");

  floatingMenuDialogElement.addEventListener('click', (event) => {
    if (event.target.closest('.floating-menu-dialog-contents') === null) {
      floatingMenuDialogElement.close();
    }
  });

  floatingMenuButtonElement.addEventListener('click', (event) => {
    floatingMenuDialogElement.showModal();

    const focusedElement = document.activeElement;

    if (focusedElement !== null) {
      focusedElement.blur();
    }
  });

  const scrollToTopButtonElement = module.querySelector(".floating-menu-item-scroll-to-top");
  scrollToTopButtonElement.addEventListener('click', (event) => {
    app.scrollToHash("#js-scroll-to-top")
    floatingMenuDialogElement.close();
  });

  let calcFloatingPositon = element => {
    let overlayModuleElement = document.querySelector("#div-imp-smarttag-1888646-0");
    if (overlayModuleElement == null) {
      return;
    }

    element.classList.add("overlay");
  }

  let initLoginState = () => {
    const userElement = module.querySelector(".floating-menu-item-user");
    const linkElement = userElement.querySelector("a.menu-link");
    const iconElement = userElement.querySelector(".icon");
    const imageElement = userElement.querySelector(".image");
    const imgElement = userElement.querySelector(".image img");
    const textElement = userElement.querySelector(".text");
    const notificationElement = userElement.querySelector(".notifications-count");

    if (loginStateStore.isUser()) {
      textElement.innerText = "通知";
      linkElement.href = "/me/notifications";
      imgElement.src = loginStateStore.getUserIconPath();
      let notificationCount = loginStateStore.getNotificationCount()

      if (notificationCount >= 1) {
        notificationElement.style.display = "block";
        notificationElement.innerText = notificationCount;
      }

      imageElement.style.display = "block";
      iconElement.remove();
    } else {
      imgElement.remove();
    }
  }

  pubsubStore.subscribe("userLoginCompleted", args => {
    initLoginState();
    calcFloatingPositon(module)
    module.style.display = "block";
  });
});
