import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let modules = document.querySelectorAll(".pickup-user-module");

  if (modules.length == 0) {
    return;
  }

  let initReadMoreState = element => {
    let description = element.querySelector(".description");
    let readMore = element.querySelector(".description-readmore");

    if (readMore === null) {
      return
    }

    // readMore.addEventListener("click", function() {
    //   description.style.maxHeight = "none";
    //   description.style.overflow = "none";
    //   readMore.style.display = "none";
    // });
  }

  let initState = () => {
    modules.forEach(element => {
      initReadMoreState(element)
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
    appendRootElement.querySelectorAll(".pickup-user-module").forEach(element => {
      initReadMoreState(element)
    });
  });
});
