import pubsubStore from "../pubsub_store";

class FooterFunctionMenu {
  constructor() {
    this.module = document.querySelector('.footer-function-menu-module');
    if (!this.module) return;

    this.init();
    this.bindEvents();
  }

  init() {
    this.fetchTodayReleaseBooksCount();
    this.handleOverlay();
  }

  bindEvents() {
    pubsubStore.subscribe('userLoginCompleted', () => this.init());
  }

  handleOverlay() {
    const overlayElement = document.querySelector('#div-imp-smarttag-1888646-0');
    if (!overlayElement) return;
    // this.module.classList.add('overlay');
  }

  async fetchTodayReleaseBooksCount() {
    try {
      const response = await fetch('/api/new_books/information');
      const { today_release_books_count: count } = await response.json();
      this.updateBookCount(count);
    } catch (error) {
      console.error('Failed to fetch book count:', error);
    }
  }

  updateBookCount(count) {
    const countElements = this.module.querySelectorAll('.today-release-books-count');
    countElements.forEach(element => {
      element.textContent = count > 0 ? count : '';
    });
  }
}

document.addEventListener('DOMContentLoaded', () => new FooterFunctionMenu());
