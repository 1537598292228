import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", () => {
  pubsubStore.subscribe("userLoginCompleted", args => {
    const smarttagIds = [
      "#div-imp-smarttag-1888646-0",
      "#div-imp-smarttag-1888644-0",
    ];
    let isSmartPhone = app.isSmartPhone();

    if (loginStateStore.isLoggedIn() == true) {
      smarttagIds.forEach(id => {
        const element = document.querySelector(id);
        if (element) {
          //console.log("smarttag ", id, "display none");
          // element.style.display = "none";
          if (isSmartPhone) {
            console.log("smarttag ", id, "bottom 50px");
            element.style.bottom = "50px";
          }
        }
      });
    } else {
      smarttagIds.forEach(id => {
        const element = document.querySelector(id);
        if (element) {
          if (isSmartPhone) {
            console.log("smarttag ", id, "bottom 50px");
            element.style.bottom = "50px";
          }
        }
      });
    }
  });
});
